import * as firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyACv-yrVx8pUoWKokxMfYSQdBgyvI-LdjI",
  authDomain: "photography-portfolio-bd337.firebaseapp.com",
  projectId: "photography-portfolio-bd337",
  storageBucket: "photography-portfolio-bd337.appspot.com",
  messagingSenderId: "667817484082",
  appId: "1:667817484082:web:bc5b2a2fe13cc8799a412b",
  measurementId: "G-H1PF6T2F1F",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };
